import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import UploadPdf from "./UploadPdf";
import Typography from "@material-ui/core/Typography";

export default function AddApplicant({
  addApplicant,
  handleClickOpenApplicant,
  handleCloseApplicant,
  job_id,
  compId,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={addApplicant}
        onClose={handleCloseApplicant}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "20px" }}>
          <Typography variant="h4" color="initial" style={{ marginBottom: 2 }}>
            Add Applicant
          </Typography>
          <UploadPdf
            handleCloseApplicant={handleCloseApplicant}
            job_id={job_id}
            compId={compId}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
