import { Avatar, ListItemText, Menu, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../assets/getwork_new.png";
import NewSideBar from "../components/NewSideBar";
import { praveshAxiosGetReq } from "../util/apiCall";
import { logoutAllUsers, logoutUser } from "../util/LogoutFunction";
import { FailureAlert } from "../util/Notifications";
import MainContent from "./MainContent";
import { subsectionsToRemove } from "../constants";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#007BFF",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 15,
    paddingLeft: 10,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: ".5px solid #b0b6ba",
    // borderBottom: "1px solid rgba(0 0 0 0.12)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Layout() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const userName = `${userData.first_name} ${userData.last_name}`;
  //   const [expanded, setExpanded] = useState(panel || "panel1");
  const [sideBarData, setSideBarData] = useState(null);
  const [modifiedSideBarData, setmodifiedSideBarData] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const checkUrlAccessValidity = (path) => {
    let flag = false;
    const check = sideBarData?.map((item) => {
      const check2 = item?.subsection?.map((item2) => {
        if (
          path.includes(item2?.path) ||
          path.includes("/admin/new-dashboard") ||
          path.includes("/admin/welcome_to_getwork")
        ) {
          flag = true;
        } else {
          // console.log("Match not found!", "path", path, "item path", item2?.path);
          // FailureAlert("Access Denied for the section!")
        }
      });
    });
    return flag;
  };

  useEffect(() => {
    if (sideBarData?.length > 0) {
      const matched = checkUrlAccessValidity(location?.pathname);
      if (!matched) {
        history.push("/admin/new-dashboard");
        window.location.reload();
      }
    }
  }, [sideBarData, location?.pathname]);

  useEffect(() => {
    if (sideBarData?.length > 0) {
      // for removing these subsections from the sidebar option

      const temp = sideBarData?.map((item) => {
        const subsection = item?.subsection?.filter((item2) => {
          if (subsectionsToRemove.includes(item2?.key)) {
            // return ({ ...item2, selected: !item?.selected })
            return false;
          } else {
            return true;
          }
        });
        return { ...item, subsection: subsection };
      });

      // welcome section is being removed here
      const temp2 = temp?.filter((item) => {
        if (item?.section === "Welcome Page") {
          return false;
        } else {
          return true;
        }
      });

      setmodifiedSideBarData(temp2);
      // setSideBarData(temp)
    }
  }, [sideBarData]);

  //   const handleChange = (panel) => (event, newExpanded) => {
  //     localStorage.setItem("panel", panel);
  //     setExpanded(newExpanded ? panel : false);
  //   };

  const getSideBarData = async () => {
    const endPoint = "/api/admin/sidebar/";

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setSideBarData(res?.data?.data);
      } else {
        FailureAlert("Some Error occured!");
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getSideBarData();
  }, []);



  const [collapseSubNav, setCollapseSubNav] = useState(true);
  const handleMouseLeave = () => {
    setCollapseSubNav(true);
  };

  const handleMouseEnter = () => {
    setCollapseSubNav(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <img src={logo} alt="Getwork_Logo" height="50px" width="50px" />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ color: "#000", marginRight: "1rem" }}>
              {/* <QueryBuilder style={{marginRight:"0.5rem"}}/>{dateTime} */}
            </Typography>
            <Typography
              style={{ fontSize: 12, color: "#000", marginRight: 12 }}
            >
              {userName}
            </Typography>
            <div>
              <Avatar
                style={{ backgroundColor: "#007BFF", cursor: "pointer" }}
                onClick={handleClick}
              >
                {userName.charAt(0).toUpperCase()}
              </Avatar>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem
                  onClick={(e) => {
                    logoutUser();
                  }}
                >
                  <ListItemText primary="Logout" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={(e) => {
                    logoutAllUsers();
                  }}
                >
                  <ListItemText primary="Logout All" />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div
        className="nav"
        id="navbar"
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        <nav className="nav__container">
          <div style={{ marginTop: "70px" }}>
            <div style={{ height: "70vh" }}>
              {modifiedSideBarData?.map((item, index) => {
                return (
                  <NewSideBar
                    items={item}
                    key={index}
                    setCollapseSubNav={setCollapseSubNav}
                    collapseSubNav={collapseSubNav}
                  />
                );
              })}
            </div>
          </div>
        </nav>
      </div>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <MainContent />
        </Container>
      </main>
    </div>
  );
}
