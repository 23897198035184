import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ResumeTableHeading from "./ResumeTableHeading";
import InfoResumeTable from "./InfoResumeTable";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { praveshAxiosGetReq } from "../../util/apiCall";
import { CustomTextField } from "../common/InputText/InputField";

export default function UploadResumeDialog({
  uploadResume,
  handleClickOpenUploadResume,
  handleCloseUploadResume,
  job_id,
}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [expanded, setExpanded] = React.useState(false);

  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [resumeData, setResumeData] = React.useState([]);
  const [callResumeData, setCallResumeData] = React.useState(null);
  const [resumeDialogLoader, setResumeDialogLoader] = React.useState(false);

  // React.useEffect(() => {
  //   if (job_id)
  //     fetchApi({
  //       url: `${PRAVESH_API}company_saas/bulk_resume_apply?job_id=${job_id}`,
  //       setResponse: setResumeData,
  //       setLoading: setResumeDialogLoader,
  //     });
  // }, [job_id]);

  React.useEffect(() => {
    if (callResumeData) {
      setResumeData((prev) => [...prev, ...callResumeData?.data?.data]);
      setTotalCount(callResumeData?.data?.count);
    }
  }, [callResumeData]);

  const checkStatus = () => {
    return selectedStatus ? `&status=${selectedStatus.value}` : "";
  };

  const [triggerApiCall, setTriggerApiCall] = React.useState(false);
  React.useEffect(() => {
    setResumeDialogLoader(true);
    setResumeData([]);
    setPage(0);
    setTriggerApiCall(true);
  }, [selectedStatus]);

  const getResumeList = async () => {
    const endPoint = `/api/admin/bulk_resume_apply/?job_id=${job_id}&page=${
      page + 1
    }${checkStatus()}`;

    setResumeDialogLoader(true);

    try {
      const res = await praveshAxiosGetReq(endPoint);
      if (res?.data?.success) {
        setCallResumeData(res?.data);
      } else {
        FailureAlert(res?.data?.error);
      }
    } catch (error) {
      FailureAlert("Failed to fetch resumes.");
      throw error;
    } finally {
      setResumeDialogLoader(false);
    }
  };

  React.useEffect(() => {
    if (!triggerApiCall && job_id && resumeData.length / 4 === page) {
      // fetchApi({
      //   url: `${PRAVESH_API}company_saas/bulk_resume_apply?job_id=${
      //     job_id
      //   }&page=${page + 1}${checkStatus()}`,
      //   setResponse: setCallResumeData,
      //   setLoading: setResumeDialogLoader,
      // });
      getResumeList();
    }
  }, [page]);

  React.useEffect(() => {
    if (triggerApiCall && job_id && resumeData.length / 4 === page) {
      //       fetchApi({
      //         url: `${PRAVESH_API}company_saas/bulk_resume_apply?job_id=${
      //           job_id
      //         }&page=${page + 1}${checkStatus()}`,
      //         setResponse: setCallResumeData,
      //         setLoading: setResumeDialogLoader,
      //       });
      getResumeList();
    }
    setTriggerApiCall(false);
  }, [triggerApiCall]);

  return (
    <React.Fragment>
      <Dialog
        open={uploadResume}
        onClose={handleCloseUploadResume}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box style={{ position: "relative", padding: "25px" }}>
          <IconButton
            onClick={handleCloseUploadResume}
            color="primary"
            style={{ position: "absolute", top: "0px", right: "0px" }}
          >
            <CancelIcon />
          </IconButton>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                Uploaded Resume
              </Typography>

              <Typography
                variant="body2"
                style={{ marginBottom: 12, color: "#6C757D" }}
              >
                You can view your uploaded resume here. If your resume is still
                uploading, please give it a few minutes
              </Typography>
            </div>
            <Autocomplete
              options={Status}
              getOptionLabel={(option) => `${option?.title}`}
              value={selectedStatus}
              onChange={(e, newValue) => {
                setSelectedStatus(newValue);
              }}
              style={{
                borderRadius: "8px",
                width: "185px",
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  label="Status"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true, // Ensures the field is non-typeable
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <Box>
              <ResumeTableHeading />
            </Box>
            <InfoResumeTable
              resumeData={resumeData}
              resumeDialogLoader={resumeDialogLoader}
              page={page}
              setPage={setPage}
              totalCount={totalCount}
            />
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

const Status = [
  { value: "UPLOADED", title: "Uploaded" },
  { value: "FAILED", title: "Failed" },
  { value: "APPLIED", title: "Applied" },
  { value: "ALREADY_APPLIED", title: "Already Applied" },
];
