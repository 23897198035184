import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/styles";


export const CustomTextField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    height: "40px",
    "& fieldset": {
      borderRadius: "30px",
      color: "#7e7e7e",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});

export const CustomActionChipTextField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
    backgroundColor: "#fff",
  },
  "& label.Mui-focused": {
    backgroundColor: "#fff",
    color: "#6C757D",
  },

  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    minHeight: "40px",
    "& fieldset": {
      borderRadius: "8px",
      color: "#7e7e7e",
      border: "1px solid #B0B6BA",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});
