import React, { useState } from "react";
import {
  Dialog,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import PhoneIcon from "@material-ui/icons/Phone";
import { niyuktiAxiosGetReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { convertGridRowsPropToState } from "@material-ui/data-grid";

export default function AICallingDialog({
  openAICallingDialog,
  onClose,
  SelectedID,
  setSelectedID,
  bulkSelect,
  job_id,
  getFilterString,
  currStatusId,
  currRoundId,
  step,
  stepData,
  setstepData,
}) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("xs");
  const [aiCallingLoader, setAiCallingLoader] = useState(false);

  const onContinueClicked = () => {
    setAiCallingLoader(true);

    let url = "";
    if (bulkSelect) {
      url = `/api/ai_job/company/bot_calling/?job_id=${job_id}&is_bulk=1${
        currStatusId ? `&status_id=${currStatusId}` : ""
      }${currRoundId ? `&round_id=${currRoundId}` : ""}${getFilterString()}`;
    } else {
      const userId = SelectedID[0].user_id;
      url = `/api/ai_job/company/bot_calling/?job_id=${job_id}&user_id=${userId}&is_bulk=0`;
    }

    niyuktiAxiosGetReq(url)
      .then((res) => {
        if (res.data.success) {
          SuccessAlert(res.data?.data?.message);
          setstepData((prev) => ({
            ...prev,
            [step]: {
              ...prev[step],
              data: prev[step].data.map((item) => {
                if (bulkSelect || SelectedID[0]?.index === item?.index) {
                  setSelectedID([
                    {
                      ...item,
                      is_call_queued: true,
                    },
                  ]);
                  return {
                    ...item,
                    is_call_queued: true,
                  };
                }
                return item;
              }),
            },
          }));
          onClose();
        } else {
          FailureAlert(res.data?.error);
        }
      })
      .catch((err) => {
        FailureAlert("Something went wrong");
      })
      .finally(() => {
        setAiCallingLoader(false);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openAICallingDialog}
      >
        {!bulkSelect && SelectedID[0]?.is_call_queued ? (
          <Box style={{ position: "relative" }}>
            <IconButton
              onClick={onClose}
              color="primary"
              style={{ position: "absolute", top: 5, right: 5 }}
            >
              <CancelIcon />
            </IconButton>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PhoneIcon
                style={{ fontSize: 60, margin: "16px 0", color: "#4caf50" }}
              />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                  marginBottom: 8,
                }}
              >
                Your call is queued
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#656565",
                  textAlign: "center",
                }}
              >
                Candidates receive three calls daily, and you
                <br /> can view their responses in our AI Calling tab.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "40px",
                  boxShadow: "none",
                  marginTop: 16,
                  marginBottom: 16,
                }}
                onClick={onClose}
              >
                "Okay Got it!"
              </Button>
            </Box>
          </Box>
        ) : (
          <Box style={{ position: "relative" }}>
            <IconButton
              onClick={onClose}
              color="primary"
              style={{ position: "absolute", top: 5, right: 5 }}
            >
              <CancelIcon />
            </IconButton>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PhoneIcon
                style={{ fontSize: 60, margin: "16px 0", color: "#4caf50" }}
              />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                  marginBottom: 8,
                }}
              >
                Continue to arrange an AI interview call
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#656565",
                  textAlign: "center",
                }}
              >
                 Candidates receive three calls daily,
                
                {bulkSelect && (
                  <>
                  <br />
                  Provided the candidate's AI call hasn't initiated before.
                  </>
                )}
                <br />You can view their responses in our AI Calling tab.
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    boxShadow: "none",
                    marginTop: 16,
                    marginBottom: 16,
                    marginRight: 8,
                    width: "100px",
                  }}
                  onClick={onClose}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    height: "40px",
                    boxShadow: "none",
                    marginTop: 16,
                    marginBottom: 16,
                    marginLeft: 8,
                    width: "100px",
                  }}
                  onClick={onContinueClicked}
                >
                  {aiCallingLoader ? (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Dialog>
    </React.Fragment>
  );
}
