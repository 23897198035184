import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const CenteredLoaderPopup = ({ remainingTime, setRemainingTime, onClose }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    if (remainingTime === 0) {
      onClose(); // Auto close when time reaches 0
    }

    return () => clearInterval(timer); // Cleanup timer
  }, [remainingTime, onClose, setRemainingTime]);

  return (
    <Box
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
        backdropFilter: "blur(8px)", // Blur effect
      }}
    >
      <Box
        style={{
          position: "relative",
          backgroundColor: "#f9f9f9",
          borderRadius: "16px",
          width: "400px",
          textAlign: "center",
          padding: "24px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          color: "#333",
        }}
      >
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "#555",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress
            size={80}
            color="primary"
          />
          <Typography
            variant="h6"
            color="primary"
            style={{
              marginTop: "16px",
              fontWeight: 600,
              // color: "#2196f3",
            }}
          >
            Waiting for Authorization...
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginTop: "8px",
              color: "#555",
              fontSize: "16px",
            }}
          >
            Remaining Time: {remainingTime}s
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CenteredLoaderPopup;