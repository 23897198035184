import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Quill } from "react-quill";

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);
// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}
// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);
// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];

Quill.register(Font, true);
// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};
// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];
export const Editor = ({
  className,
  state,
  setState,
  from1 = "rest",
  setCount = () => {},
  limit = 999999999999999,
}) => {
  // const [state, setState] = React.useState({ value: null });
  const reactQuillRef = React.useRef();

  const [from, setFrom] = useState(from1 ? from1 : "rest");
  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor;
    setCount(unprivilegedEditor?.getLength());
    if (
      from === "about" &&
      unprivilegedEditor?.getLength() > 255 &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    } else if (
      from === "desc" &&
      unprivilegedEditor?.getLength() > 511 &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    }
  };

  const handleChange = (value) => {
    const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor;
    /*  setState(value); */
    if (from === "about" || from === "desc") {
      if (unprivilegedEditor?.getLength() > limit) {
        setState(unprivilegedEditor?.getText().slice(0, limit - 1));
        setCount(limit);
      } else {
        setState(value);
        setCount(unprivilegedEditor?.getLength());
      }
    } else {
      unprivilegedEditor?.getLength() === 1 ? setState("") : setState(value);
    }
  };

  //   let imgLogoTag = <img src={getworklogo} width="100px" height="100px" />;
  return (
    <div className="text-editor">
      {/* <EditorToolbar /> */}
      <div
        id="toolbar"
        style={{ borderBottom: "none" }}
        className={`toolbar ${className}`}
      >
        <span className="ql-formats"></span>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
          <button className="ql-blockquote" />
          <button className="ql-direction" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" />
          <select className="ql-color" />
          <select className="ql-background" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-image" />
        </span>
        <span className="ql-formats">
          <button className="ql-formula" />
          <button className="ql-code-block" />
          <button className="ql-clean" />
        </span>
        <span className="ql-formats">
          <button className="ql-undo">
            <CustomUndo />
          </button>
          <button className="ql-redo">
            <CustomRedo />
          </button>
        </span>
      </div>
      <ReactQuill
        theme="snow"
        value={state}
        onChange={handleChange}
        onKeyDown={
          from === "about" || from === "desc" ? checkCharacterCount : () => {}
        }
        style={{ whiteSpace: "pre-wrap", height: "200px", overflowY: "auto" }}
        ref={reactQuillRef}
        placeholder={"Start Writing Details..."}
        //modules={{ modules, toolbar: `.${className}` }}
        modules={{ toolbar: `.${className}` }}
        formats={formats}
      />
    </div>
  );
};
export default Editor;
