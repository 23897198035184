import {
  Button,
  IconButton,
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
  Backdrop,
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { praveshAxiosPostReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";

const svg = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="22" fill="#F5F5F5" />
    <path
      d="M18.9999 27.7485C18.5899 27.7485 18.2499 27.4085 18.2499 26.9985V22.8085L17.5299 23.5285C17.2399 23.8185 16.7599 23.8185 16.4699 23.5285C16.1799 23.2385 16.1799 22.7585 16.4699 22.4685L18.4699 20.4685C18.6799 20.2585 19.0099 20.1885 19.2899 20.3085C19.5699 20.4185 19.7499 20.6985 19.7499 20.9985V26.9985C19.7499 27.4085 19.4099 27.7485 18.9999 27.7485Z"
      fill="#00203F"
    />
    <path
      d="M20.9999 23.7514C20.8099 23.7514 20.6199 23.6814 20.4699 23.5314L18.4699 21.5314C18.1799 21.2414 18.1799 20.7614 18.4699 20.4714C18.7599 20.1814 19.2399 20.1814 19.5299 20.4714L21.5299 22.4714C21.8199 22.7614 21.8199 23.2414 21.5299 23.5314C21.3799 23.6814 21.1899 23.7514 20.9999 23.7514Z"
      fill="#00203F"
    />
    <path
      d="M25 32.75H19C13.57 32.75 11.25 30.43 11.25 25V19C11.25 13.57 13.57 11.25 19 11.25H24C24.41 11.25 24.75 11.59 24.75 12C24.75 12.41 24.41 12.75 24 12.75H19C14.39 12.75 12.75 14.39 12.75 19V25C12.75 29.61 14.39 31.25 19 31.25H25C29.61 31.25 31.25 29.61 31.25 25V20C31.25 19.59 31.59 19.25 32 19.25C32.41 19.25 32.75 19.59 32.75 20V25C32.75 30.43 30.43 32.75 25 32.75Z"
      fill="#00203F"
    />
    <path
      d="M32 20.7485H28C24.58 20.7485 23.25 19.4185 23.25 15.9985V11.9985C23.25 11.6985 23.43 11.4185 23.71 11.3085C23.99 11.1885 24.31 11.2585 24.53 11.4685L32.53 19.4685C32.74 19.6785 32.81 20.0085 32.69 20.2885C32.57 20.5685 32.3 20.7485 32 20.7485ZM24.75 13.8085V15.9985C24.75 18.5785 25.42 19.2485 28 19.2485H30.19L24.75 13.8085Z"
      fill="#00203F"
    />
  </svg>
);

const deleteIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5001 5.60937C17.4834 5.60937 17.4584 5.60937 17.4334 5.60937C13.0251 5.1677 8.62505 5.00104 4.26672 5.4427L2.56672 5.60937C2.21672 5.6427 1.90839 5.3927 1.87505 5.0427C1.84172 4.6927 2.09172 4.3927 2.43339 4.35937L4.13339 4.1927C8.56672 3.7427 13.0584 3.9177 17.5584 4.35937C17.9001 4.3927 18.1501 4.70104 18.1167 5.0427C18.0917 5.3677 17.8167 5.60937 17.5001 5.60937Z"
      fill="#353535"
    />
    <path
      d="M7.08338 4.76797C7.05005 4.76797 7.01672 4.76797 6.97505 4.75964C6.64172 4.7013 6.40838 4.3763 6.46672 4.04297L6.65005 2.9513C6.78338 2.1513 6.96671 1.04297 8.90838 1.04297H11.0917C13.0417 1.04297 13.225 2.19297 13.35 2.95964L13.5334 4.04297C13.5917 4.38464 13.3584 4.70964 13.025 4.75964C12.6834 4.81797 12.3584 4.58464 12.3084 4.2513L12.125 3.16797C12.0084 2.44297 11.9834 2.3013 11.1 2.3013H8.91672C8.03338 2.3013 8.01672 2.41797 7.89172 3.15964L7.70005 4.24297C7.65005 4.5513 7.38338 4.76797 7.08338 4.76797Z"
      fill="#353535"
    />
    <path
      d="M12.675 18.9596H7.325C4.41666 18.9596 4.3 17.3513 4.20833 16.0513L3.66666 7.65962C3.64166 7.31795 3.90833 7.01795 4.25 6.99295C4.6 6.97628 4.89166 7.23462 4.91666 7.57628L5.45833 15.968C5.55 17.2346 5.58333 17.7096 7.325 17.7096H12.675C14.425 17.7096 14.4583 17.2346 14.5417 15.968L15.0833 7.57628C15.1083 7.23462 15.4083 6.97628 15.75 6.99295C16.0917 7.01795 16.3583 7.30962 16.3333 7.65962L15.7917 16.0513C15.7 17.3513 15.5833 18.9596 12.675 18.9596Z"
      fill="#353535"
    />
    <path
      d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z"
      fill="#353535"
    />
    <path
      d="M12.0834 11.043H7.91675C7.57508 11.043 7.29175 10.7596 7.29175 10.418C7.29175 10.0763 7.57508 9.79297 7.91675 9.79297H12.0834C12.4251 9.79297 12.7084 10.0763 12.7084 10.418C12.7084 10.7596 12.4251 11.043 12.0834 11.043Z"
      fill="#353535"
    />
  </svg>
);

const filesvg = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5001 18.9596H7.50008C2.97508 18.9596 1.04175 17.0263 1.04175 12.5013V7.5013C1.04175 2.9763 2.97508 1.04297 7.50008 1.04297H11.6667C12.0084 1.04297 12.2917 1.3263 12.2917 1.66797C12.2917 2.00964 12.0084 2.29297 11.6667 2.29297H7.50008C3.65841 2.29297 2.29175 3.65964 2.29175 7.5013V12.5013C2.29175 16.343 3.65841 17.7096 7.50008 17.7096H12.5001C16.3417 17.7096 17.7084 16.343 17.7084 12.5013V8.33464C17.7084 7.99297 17.9917 7.70964 18.3334 7.70964C18.6751 7.70964 18.9584 7.99297 18.9584 8.33464V12.5013C18.9584 17.0263 17.0251 18.9596 12.5001 18.9596Z"
      fill="#353535"
    />
    <path
      d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z"
      fill="#353535"
    />
    <path
      d="M10.8333 11.457H5.83325C5.49159 11.457 5.20825 11.1737 5.20825 10.832C5.20825 10.4904 5.49159 10.207 5.83325 10.207H10.8333C11.1749 10.207 11.4583 10.4904 11.4583 10.832C11.4583 11.1737 11.1749 11.457 10.8333 11.457Z"
      fill="#353535"
    />
    <path
      d="M9.16659 14.793H5.83325C5.49159 14.793 5.20825 14.5096 5.20825 14.168C5.20825 13.8263 5.49159 13.543 5.83325 13.543H9.16659C9.50825 13.543 9.79159 13.8263 9.79159 14.168C9.79159 14.5096 9.50825 14.793 9.16659 14.793Z"
      fill="#353535"
    />
  </svg>
);

function UploadPdf({ handleCloseApplicant, job_id, compId }) {
  // const [check, setCheck] = useState(true);
  const [progress, setProgress] = useState(false);
  const [value, setValue] = useState(0);
  const inputRef = useRef(null);
  const [prefill, setPrefill] = useState(false);
  const [file, setFile] = useState([]);

  const handleFile = (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFiles = [...file];

    const allowedExtensions = ["doc", "docx", "pdf"];

    newFiles.forEach((newFile) => {
      const fileExtension = newFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        FailureAlert(
          `File "${newFile.name}" is not a valid format. Only .doc, .docx, or .pdf are allowed.`
        );
        return;
      }

      if (newFile.size > 5 * 1024 * 1024) {
        FailureAlert(`File "${newFile.name}" exceeds 5 MB.`);
        return;
      }

      const existingFileIndex = updatedFiles.findIndex(
        (file) => file.name === newFile.name
      );

      if (existingFileIndex === -1) {
        updatedFiles.push(newFile);
      } else {
        FailureAlert(`File "${newFile.name}" already exists.`);
      }
    });

    setFile([...updatedFiles]);
  };

  const handleRemoveFile = (fileName) => {
    const updatedFiles = file.filter((file) => file.name !== fileName);
    setFile(updatedFiles);
    setPrefill(false);
  };

  function formatFileSize(bytes, decimalPoint) {
    if (bytes === 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const BorderLinearProgress = withStyles({
    root: {
      height: 8,
      borderRadius: 5,
      backgroundColor: "#F5F5F5",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1976D2",
    },
  })((props) => <LinearProgress {...props} />);

  const [addApplicantLoader, setAddApplicantLoader] = useState(false);
  useEffect(() => {
    let progressBar = progress;
    if (progressBar === true) {
      const timerId = setTimeout(() => {
        const interval = setInterval(() => {
          setValue((prevValue) => Math.min(prevValue + 10, 100));
          if (setValue === 100) {
            clearInterval(interval);
          }
        }, 280);
      }, 0);
      return () => clearTimeout(timerId);
    }
  }, [progress]);

  const [isUploading, setIsUploading] = useState(false);

  const onAddApplicantClicked = () => {
    if (!file || file?.length === 0) {
      FailureAlert("Please select atleast one resume");
      return;
    }
    setProgress(true);
    setIsUploading(true);
    const formData = new FormData();
    formData.append("job_id", job_id);
    file.forEach((file, index) => {
      formData.append("resumes", file);
    });

    praveshAxiosPostReq(
      `/api/admin/bulk_resume_apply/?company_id=${compId}`,
      formData
    )
      .then((res) => {
        if (res?.data?.success) {
          SuccessAlert(res?.data?.data?.message);
          handleCloseApplicant();
        } else {
          FailureAlert(res?.data?.error);
        }
        setAddApplicantLoader(false);
        setIsUploading(false);
      })
      .catch((err) => {
        setAddApplicantLoader(false);
        setIsUploading(false);
        FailureAlert(
          err?.res?.data?.message ||
            "Failed to upload resumes. Please try again."
        );
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "500px",
          border: "0.72px dashed #B1C1CF",
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <div style={{ cursor: "pointer", marginTop: "40px" }}>
          <div>
            {!file && !prefill ? (
              <div
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                {svg}
              </div>
            ) : (
              <div
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                {svg}
              </div>
            )}
          </div>
        </div>

        <div>
          <div>
            <input
              ref={inputRef}
              type="file"
              multiple
              onChange={handleFile}
              style={{ display: "none" }}
              accept=".doc,.docx,.pdf"
            />
          </div>
        </div>
        <div
          style={{
            paddingBottom: 20,
          }}
        >
          <Typography
            variant="h5"
            color="primary"
            style={{
              fontWeight: "600",
              textAlign: "center",
              marginBottom: 0.5,
            }}
          >
            Upload Resumes
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#6C757D",
              textAlign: "center",
              marginBottom: 1,
            }}
          >
            Accepts .pdf, .doc, .docx
            <br />
            (Max. File size per resume: 5 MB)
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
        }}
      >
        {file.length === 1 ? (
          <>
            {file.map((file) => {
              return (
                <>
                  <div
                    style={{
                      border: "1px solid #CACACA",
                      borderRadius: 7,
                      padding: 15,
                      marginTop: 20,
                      paddingBottom: 0,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ color: "#00203F" }}>{filesvg}</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 8,
                          }}
                        >
                          <Typography variant="body2" style={{ color: "#000" }}>
                            {" "}
                            {file?.name}
                          </Typography>
                          <Typography
                            style={{ color: "#6C757D", fontSize: "12px" }}
                          >
                            {formatFileSize(file?.size)}
                          </Typography>
                        </div>
                      </div>
                      <IconButton
                        onClick={() => handleRemoveFile(file?.name)}
                        style={{ color: "#000000" }}
                        disabled={isUploading}
                      >
                        {deleteIcon}
                      </IconButton>
                      {/* {check && (
                        <CheckCircleIcon style={{ color: "#50C878" }} />
                      )} */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={value}
                          style={{ marginTop: 2, marginBottom: 2 }}
                        />
                      </div>
                      <Typography
                        color="primary"
                        style={{
                          textAlign: "center",
                          color: "#353535",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                      >
                        {value}%
                      </Typography>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          file.length > 1 && (
            <>
              <div
                style={{
                  border: "1px solid #CACACA",
                  borderRadius: 7,
                  padding: 15,
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ color: "#00203F" }}>{filesvg}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 8,
                      }}
                    >
                      <Typography variant="body2" style={{ color: "#000" }}>
                        {" "}
                        {file?.length} Resumes Selected
                      </Typography>
                      <Typography
                        color="primary"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        Click 'Add Applicant' and check 'Resume' section for
                        upload status
                      </Typography>
                    </div>
                  </div>
                  <IconButton
                    onClick={() => setFile("")}
                    style={{ color: "#000000" }}
                    disabled={isUploading}
                  >
                    {deleteIcon}
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={value}
                      style={{ marginTop: 2, marginBottom: 2 }}
                    />
                  </div>
                  <Typography
                    color="primary"
                    style={{
                      textAlign: "center",
                      color: "#353535",
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    {value}%
                  </Typography>
                </div>
              </div>
            </>
          )
        )}
        <Grid
          container
          spacing={2}
          style={{ width: "100%", margin: 0, marginTop: "20px" }}
        >
          <Grid md={6} style={{ display: "grid", paddingRight: 1 }}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={handleCloseApplicant}
              style={{ boxShadow: "none" }}
              disabled={addApplicantLoader && isUploading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid md={6} style={{ display: "grid", paddingLeft: 1 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isUploading}
              style={{ boxShadow: "none" }}
              onClick={onAddApplicantClicked}
            >
              {addApplicantLoader ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Add Applicant"
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Backdrop open={isUploading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default UploadPdf;
