import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  CircularProgress,
  TableFooter,
  IconButton,
  TablePagination,
  Tooltip,
  Box,
  Typography,
  Toolbar,
} from "@material-ui/core";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InfoIcon from "@material-ui/icons/Info";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;
  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <Box style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#00203F",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))(TableRow);

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day}-${month}-${year} & ${hours}:${minutes} ${ampm}`;
}

export default function InfoResumeTable({
  resumeData,
  resumeDialogLoader,
  page,
  setPage,
  totalCount,
}) {
  const [status, setStatus] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  return (
    <>
      {resumeDialogLoader ? (
        <div
          style={{
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={40} style={{ color: "#00203f" }} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{ height: "300px" }}
          className="leftlivejobcardscroll"
        >
          <Table aria-label="customized table">
            <TableBody>
              {resumeData
                .slice(
                  rowsPerPage === -1 ? 0 : page * rowsPerPage,
                  rowsPerPage === -1
                    ? resumeData.length
                    : page * rowsPerPage + rowsPerPage
                )
                ?.map((row) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: "30%" }}
                    >
                      <Typography
                        style={{
                          maxWidth: "230px",
                          cursor: "pointer",
                          color: "#424242",
                        }}
                        noWrap
                      >
                        <Tooltip title={row?.file_name ?? ""} placement="right">
                          <span>{row?.file_name ?? ""}</span>
                        </Tooltip>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: "20%" }}
                    >
                      {`${row?.user__first_name} ${row?.user__last_name}`}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{
                        width: "15%",
                        color: status ? "#029E73" : "Already Applied",
                      }}
                    >
                      {row?.status ? (
                        <div
                          style={{
                            color: statusColors[row?.status],
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ color: statusColors[row?.status] }}>
                            {row?.status}
                          </span>
                          {row?.remark && (
                            <Tooltip
                              title={row?.remark ?? ""}
                              placement="right"
                            >
                              <InfoIcon
                                style={{
                                  color: statusColors[row?.status],
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            color: "#EE2C3C",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ color: "#EE2C3C" }}>Error</span>
                          <Tooltip
                            title="Error while uploading"
                            placement="right"
                          >
                            <InfoIcon
                              style={{ color: "#EE2C3C", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ width: "25%" }}>
                      {formatDate(row?.create_time)}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "10%" }}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        style={{
                          color: "#0B57D0",
                          borderColor: "#0B57D0",
                          boxShadow: "none",
                        }}
                        onClick={() => window.open(row?.file, "_blank")}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableFooter
              style={{
                position: "sticky",
                bottom: 0,
                background: "white",
                borderTop: "1px solid #e0e0e0",
              }}
            >
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[4]}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(event, newPage) => {
                    setPage(newPage);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

const statusColors = {
  UPLOADED: "#000000",
  APPLIED: "#029e73",
  ALREADY_APPLIED: "#6c757d",
  FAILED: "#ee2c3c",
};
