import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#00203F",
    color: theme.palette.common.white,
    padding: 10,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function ResumeTableHeading() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: "30%" }}>
              File name
            </StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Uploaded By
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: "15%" }}>
              Status
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: "25%" }}>
              Date & Time
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: "10%" }}>
              Action
            </StyledTableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
