import { Box, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { companySizes } from "../../../constants";
import YearMonthPicker from "../../common/YearMonthPicker";
import { FailureAlert } from "../../../util/Notifications";

const useStyles = makeStyles((theme) => ({
  keyvalue: {
    fontWeight: 600,
    fontSize: 16,
  },
}));

const CompanyBasicInfo = ({
  newCompanyDetails,
  setNewCompanyDetails,
  industries,
  companyCategoryList,
  eventList,
}) => {
  const classes = useStyles();

  const [about, setAbout] = useState(
    EditorState?.createWithContent(
      ContentState?.createFromBlockArray(
        convertFromHTML(
          newCompanyDetails?.companyBasicInfo?.about
            ? newCompanyDetails?.companyBasicInfo?.about
            : " "
        )
      )
    )
  );

  const [description, setDescription] = useState(
    EditorState?.createWithContent(
      ContentState?.createFromBlockArray(
        convertFromHTML(
          newCompanyDetails?.companyBasicInfo?.description
            ? newCompanyDetails?.companyBasicInfo?.description
            : " "
        )
      )
    )
  );
  const inputRefs = useRef([]);
  const [file, setFile] = useState(null);
  const getlengthTexts = (arr) => {
    let totalLength = 0;
    for (let i in arr) {
      totalLength = totalLength + arr[i]?.text.length;
    }
    return totalLength;
  };

  const [wordsLength, setWordsLength] = useState(
    getlengthTexts(convertToRaw(about.getCurrentContent())?.blocks)
  );
  const [descWordsLength, setDescWordsLength] = useState(
    getlengthTexts(convertToRaw(description.getCurrentContent())?.blocks)
  );

  const addTextField = () => {
    const nums = [...newCompanyDetails?.companyBasicInfo?.perksAndBenefits];
    setNewCompanyDetails({
      ...newCompanyDetails,
      companyBasicInfo: {
        ...newCompanyDetails?.companyBasicInfo,
        perksAndBenefits: [...nums, { perk: "", is_added: true }],
      },
    });
  };

  const addRating = () => {
    const nums = [...newCompanyDetails?.companyBasicInfo?.ratingAndReviews];
    setNewCompanyDetails({
      ...newCompanyDetails,
      companyBasicInfo: {
        ...newCompanyDetails?.companyBasicInfo,
        ratingAndReviews: [...nums, { name: "", rating: "", is_added: true }],
      },
    });
  };

  const addFounders = () => {
    const nums = [...newCompanyDetails?.companyBasicInfo?.foundersTeam];
    setNewCompanyDetails({
      ...newCompanyDetails,
      companyBasicInfo: {
        ...newCompanyDetails?.companyBasicInfo,
        foundersTeam: [
          ...nums,
          { name: "", image: null, social_link: "", designation: "" },
        ],
      },
    });
  };

  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    if (file && file.type.startsWith("image/")) {
      let nums = [...newCompanyDetails?.companyBasicInfo?.foundersTeam];
      nums[selectedIndex].image = file;
      setNewCompanyDetails({
        ...newCompanyDetails,
        companyBasicInfo: {
          ...newCompanyDetails?.companyBasicInfo,
          foundersTeam: nums,
        },
      });
      setFile(null);
    } else if (file && !file.type.startsWith("image/")) {
      FailureAlert("Upload the File in Specefic type only");
    }
  }, [file, selectedIndex]);

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Form
          className="edit__poc__details__form"
          id="edit__poc__details__form__details"
        >
          <Form.Row>
            <Form.Group as={Col} controlId="companyName">
              <Form.Label className={classes.keyvalue}>
                Company Name*
              </Form.Label>
              <Form.Control
                name="firstname"
                type="text"
                placeholder={"Company Name*"}
                value={newCompanyDetails?.companyBasicInfo?.companyName}
                onChange={(e) => {
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      companyName: e.target.value,
                    },
                  });
                }}
                required={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="registeredCompanyName">
              <Form.Label className={classes.keyvalue}>
                Registered Name*
              </Form.Label>
              <Form.Control
                name="registeredCompanyName"
                type="text"
                placeholder={"Company Registered Name*"}
                value={
                  newCompanyDetails?.companyBasicInfo?.registeredCompanyName
                }
                onChange={(e) => {
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      registeredCompanyName: e.target.value,
                    },
                  });
                }}
                required={true}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="typeSearchAutoComplete">
              <Autocomplete
                id="industry"
                options={industries}
                getOptionLabel={(item) => item.industry_name}
                style={{ height: 16, maxHeight: 10 }}
                value={newCompanyDetails?.companyBasicInfo?.industry}
                onChange={(e, newValue) => {
                  let tempIndustry = newValue ? newValue : null;
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      industry: tempIndustry,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Industry*"
                    variant="outlined"
                    style={{ display: "flex" }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group as={Col} className="typeSearchAutoComplete">
              <Autocomplete
                id="industry"
                options={companySizes}
                getOptionLabel={(item) => item.label}
                style={{ height: 16, maxHeight: 10 }}
                value={newCompanyDetails?.companyBasicInfo?.companySize}
                onChange={(e, newValue) => {
                  let tempSize = newValue ? newValue : null;
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      companySize: tempSize,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Size*"
                    variant="outlined"
                    style={{ display: "flex" }}
                  />
                )}
              />
            </Form.Group>
          </Form.Row>
          <br /> <br />
          <Form.Row>
            <Form.Group as={Col} className="typeSearchAutoCompleteIndustry">
              <Form.Label className={classes.keyvalue}>GSTIN</Form.Label>
              <Form.Control
                name="gstin"
                type="text"
                placeholder={"GST No"}
                value={newCompanyDetails?.companyBasicInfo?.gstin}
                onChange={(e) => {
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      gstin: e.target.value,
                    },
                  });
                }}
                required={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="collegedetails">
              <Form.Label className={classes.keyvalue}>
                Year Founded*
              </Form.Label>
              <YearMonthPicker
                label="Year Founded"
                selectedDate={newCompanyDetails?.companyBasicInfo?.yearFounded}
                onDateChange={(date) => {
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      yearFounded: moment(date).format("YYYY"),
                    },
                  });
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Label className={classes.keyvalue}>
            Select Organization Type*{" "}
          </Form.Label>
          <Form.Row>
            <Form.Group as={Col} type="radio" id="organizationType" required>
              <Form.Check
                inline
                type="radio"
                name="organizationType"
                id="government"
                label="Government"
                value="Government"
                checked={
                  newCompanyDetails?.companyBasicInfo?.organizationType ===
                  "Government"
                }
                onChange={(e) =>
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      organizationType: e.target.value,
                    },
                  })
                }
              />
              <Form.Check
                inline
                type="radio"
                name="organizationType"
                id="semiGovernment"
                label="Semi Private"
                value="SemiPrivate"
                checked={
                  newCompanyDetails?.companyBasicInfo?.organizationType ===
                  "SemiPrivate"
                }
                onChange={(e) =>
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      organizationType: e.target.value,
                    },
                  })
                }
              />
              <Form.Check
                inline
                type="radio"
                name="organizationType"
                id="private"
                label="Private"
                value="Private"
                checked={
                  newCompanyDetails?.companyBasicInfo?.organizationType ===
                  "Private"
                }
                onChange={(e) =>
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      organizationType: e.target.value,
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} className="typeSearchAutoComplete">
              <Autocomplete
                id="category"
                options={companyCategoryList}
                getOptionLabel={(item) => item?.type}
                style={{ height: 16, maxHeight: 10 }}
                value={newCompanyDetails?.companyBasicInfo?.category}
                onChange={(e, newValue) => {
                  let tempSize = newValue ? newValue : null;
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      category: tempSize,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Category*"
                    variant="outlined"
                    style={{ display: "flex" }}
                  />
                )}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ marginBottom: "2rem" }}>
            <Form.Group as={Col} className="typeSearchAutoComplete">
              <Autocomplete
                id="category"
                multiple
                options={eventList}
                getOptionLabel={(item) => item?.event_name}
                style={{ height: 16, maxHeight: 10 }}
                value={newCompanyDetails?.companyBasicInfo?.events}
                onChange={(e, newValue) => {
                  let tempSize = newValue ? newValue : null;
                  setNewCompanyDetails({
                    ...newCompanyDetails,
                    companyBasicInfo: {
                      ...newCompanyDetails?.companyBasicInfo,
                      events: tempSize,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Events"
                    variant="outlined"
                    style={{ display: "flex" }}
                  />
                )}
              />
            </Form.Group>

            <Form.Group as={Col} className="typeSearchAutoComplete">
              <br />

              <FormControlLabel
                label="Brand"
                control={
                  <Checkbox
                    checked={newCompanyDetails?.companyBasicInfo?.is_brand}
                    onChange={(e, newValue) => {
                      let tempSize = newValue ? newValue : null;
                      setNewCompanyDetails({
                        ...newCompanyDetails,
                        companyBasicInfo: {
                          ...newCompanyDetails?.companyBasicInfo,
                          is_brand: newValue,
                        },
                      });
                    }}
                  />
                }
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="collegedetails">
              <Form.Label
                style={{ color: `${wordsLength > 256 ? "red" : "black"}` }}
                className={classes.keyvalue}
              >{`About*${wordsLength}/256 ${
                wordsLength > 256
                  ? "About section should be less than 256 characters"
                  : ""
              }`}</Form.Label>
              <div className="editor">
                <Editor
                  editorState={about}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  editorStyle={{ border: "1px solid #C0C0C0", minHeight: 150 }}
                  onEditorStateChange={(state) => {
                    const getLength = getlengthTexts(
                      convertToRaw(state.getCurrentContent())?.blocks
                    );
                    setAbout(state);
                    setNewCompanyDetails({
                      ...newCompanyDetails,
                      companyBasicInfo: {
                        ...newCompanyDetails?.companyBasicInfo,
                        about: draftToHtml(
                          convertToRaw(state.getCurrentContent())
                        ),
                        aboutWordsLength: getLength,
                      },
                    });
                    setWordsLength(getLength);
                  }}
                />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ marginTop: "-2rem", width: "100%" }}>
            <Form.Group as={Col} controlId="collegedetails">
              <Form.Label
                style={{ color: `${descWordsLength > 516 ? "red" : "black"}` }}
                className={classes.keyvalue}
              >
                {`Company Description*${descWordsLength}/516 ${
                  descWordsLength > 516
                    ? "Description section should be less than 516 characters"
                    : ""
                }`}
                {}
              </Form.Label>
              <div className="editor">
                <Editor
                  editorState={description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  editorStyle={{ border: "1px solid #C0C0C0", minHeight: 150 }}
                  onEditorStateChange={(state) => {
                    const getLength = getlengthTexts(
                      convertToRaw(state.getCurrentContent())?.blocks
                    );

                    setDescWordsLength(getLength);
                    setDescription(state);
                    setNewCompanyDetails({
                      ...newCompanyDetails,
                      companyBasicInfo: {
                        ...newCompanyDetails?.companyBasicInfo,
                        description: draftToHtml(
                          convertToRaw(state.getCurrentContent())
                        ),
                        descripWordsLimit: getLength,
                      },
                    });
                  }}
                />
              </div>

              <div>
                <p>Rating and Reviews</p>
                {newCompanyDetails?.companyBasicInfo?.ratingAndReviews?.map(
                  (value, index) => (
                    <Box key={index} mb={2}>
                      <Form.Control
                        // name="firstname"
                        disabled={
                          !newCompanyDetails?.companyBasicInfo
                            ?.ratingAndReviews[index]?.is_added
                        }
                        type="text"
                        placeholder="Name"
                        value={
                          newCompanyDetails?.companyBasicInfo?.ratingAndReviews[
                            index
                          ]?.name
                        }
                        onChange={(e) => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.ratingAndReviews,
                          ];
                          nums[index].name = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              ratingAndReviews: nums,
                            },
                          });
                        }}
                        required={true}
                      />

                      <Form.Control
                        // name="firstname"
                        disabled={
                          !newCompanyDetails?.companyBasicInfo
                            ?.ratingAndReviews[index]?.is_added
                        }
                        style={{
                          marginTop: "10px",
                        }}
                        type="text"
                        placeholder="Rating"
                        value={
                          newCompanyDetails?.companyBasicInfo?.ratingAndReviews[
                            index
                          ]?.rating
                        }
                        onChange={(e) => {
                          // if (e.target.value <= 5) {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.ratingAndReviews,
                          ];
                          nums[index].rating = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              ratingAndReviews: nums,
                            },
                          });
                          // }
                        }}
                        required={true}
                        max={5}
                      />

                      <Button
                        onClick={() => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.ratingAndReviews,
                          ];
                          nums.splice(index, 1);
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              ratingAndReviews: nums,
                            },
                          });
                        }}
                        className={classes.button}
                        color="primary"
                        style={{ float: "right", margin: "10px" }}
                      >
                        Delete
                      </Button>
                    </Box>
                  )
                )}
                <Button
                  onClick={() => addRating()}
                  className={classes.button}
                  // variant="contained"
                  // color="primary"
                  style={{ float: "right", marginTop: "2rem" }}
                >
                  Add Rating And Reviews
                </Button>

                <p style={{ marginTop: "5rem" }}>Perks and Benefits</p>
                {/* {(newCompanyDetails?.companyBasicInfo?.perksAndBenefits?.length === 0) ?
                  (
                    <Box key={0} mb={2}>
                      <Form.Control
                        // name="firstname"
                        type="text"
                        placeholder={`Perks and Benefits ${0 + 1}`}
                        value={newCompanyDetails?.companyBasicInfo?.perksAndBenefits[0]}
                        onChange={(e) => {
                          let nums = [...newCompanyDetails?.companyBasicInfo?.perksAndBenefits]
                          nums[0] = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              perksAndBenefits: nums
                            },
                          });
                        }}
                        required={true}
                      />
                    </Box>
                  ) :
                  (<></>)} */}

                {newCompanyDetails?.companyBasicInfo?.perksAndBenefits?.map(
                  (value, index) => (
                    <Box key={index} mb={2}>
                      <Form.Control
                        // name="firstname"
                        disabled={
                          !newCompanyDetails?.companyBasicInfo
                            ?.perksAndBenefits[index]?.is_added
                        }
                        type="text"
                        placeholder={`Perks and Benefits ${index + 1}`}
                        value={
                          typeof newCompanyDetails?.companyBasicInfo
                            ?.perksAndBenefits[index] === "object"
                            ? newCompanyDetails.companyBasicInfo
                                .perksAndBenefits[index]?.perk
                            : newCompanyDetails.companyBasicInfo
                                .perksAndBenefits[index]
                        }
                        onChange={(e) => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.perksAndBenefits,
                          ];
                          nums[index].perk = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              perksAndBenefits: nums,
                            },
                          });
                        }}
                        required={true}
                      />

                      <Button
                        onClick={() => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.perksAndBenefits,
                          ];
                          nums.splice(index, 1);
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              perksAndBenefits: nums,
                            },
                          });
                        }}
                        className={classes.button}
                        color="primary"
                        style={{ float: "right", margin: "10px" }}
                      >
                        Delete
                      </Button>
                    </Box>
                  )
                )}
                <Button
                  onClick={() => addTextField()}
                  className={classes.button}
                  // variant="contained"
                  // color="primary"
                  style={{ float: "right", marginTop: "2rem" }}
                >
                  Add Perks and Benefits
                </Button>

                <p style={{ marginTop: "5rem" }}>Founders Team</p>

                {newCompanyDetails?.companyBasicInfo?.foundersTeam?.map(
                  (value, index) => (
                    <Box key={index} mb={2}>
                      <Form.Control
                        // name="firstname"
                        disabled={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.image_name
                        }
                        type="text"
                        placeholder={` Founders Name ${index + 1}`}
                        value={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.name
                        }
                        onChange={(e) => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.foundersTeam,
                          ];
                          nums[index].name = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              foundersTeam: nums,
                            },
                          });
                        }}
                        required={true}
                      />

                      <input
                        ref={(el) => (inputRefs.current[index] = el)}
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />

                      <Button
                        style={{ margin: "10px" }}
                        disabled={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.image_name
                        }
                        color="primary"
                        component="span"
                        onClick={() => {
                          inputRefs.current[index]?.click();
                          setSelectedIndex(index);
                        }}
                      >
                        Upload Image
                      </Button>
                      {newCompanyDetails?.companyBasicInfo?.foundersTeam[index]
                        ?.image?.name ? (
                        <span>
                          {
                            newCompanyDetails?.companyBasicInfo?.foundersTeam[
                              index
                            ]?.image?.name
                          }
                        </span>
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              newCompanyDetails?.companyBasicInfo?.foundersTeam[
                                index
                              ]?.image
                            )
                          }
                        >
                          {
                            newCompanyDetails?.companyBasicInfo?.foundersTeam[
                              index
                            ]?.image_name
                          }
                        </span>
                      )}

                      <Form.Control
                        // name="firstname"
                        disabled={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.image_name
                        }
                        type="text"
                        placeholder={`Social Link ${index + 1}`}
                        value={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.social_link
                        }
                        onChange={(e) => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.foundersTeam,
                          ];
                          nums[index].social_link = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              foundersTeam: nums,
                            },
                          });
                        }}
                        required={true}
                      />

                      <Form.Control
                        // name="firstname"
                        disabled={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.image_name
                        }
                        style={{ marginTop: "10px" }}
                        type="text"
                        placeholder={`Designation ${index + 1}`}
                        value={
                          newCompanyDetails?.companyBasicInfo?.foundersTeam[
                            index
                          ]?.designation
                        }
                        onChange={(e) => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.foundersTeam,
                          ];
                          nums[index].designation = e.target.value;
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              foundersTeam: nums,
                            },
                          });
                        }}
                        required={true}
                      />

                      <Button
                        onClick={() => {
                          let nums = [
                            ...newCompanyDetails?.companyBasicInfo
                              ?.foundersTeam,
                          ];
                          nums.splice(index, 1);
                          setNewCompanyDetails({
                            ...newCompanyDetails,
                            companyBasicInfo: {
                              ...newCompanyDetails?.companyBasicInfo,
                              foundersTeam: nums,
                            },
                          });
                        }}
                        className={classes.button}
                        color="primary"
                        style={{ float: "right", margin: "10px" }}
                      >
                        Delete
                      </Button>
                    </Box>
                  )
                )}
                <Button
                  onClick={() => addFounders()}
                  className={classes.button}
                  // variant="contained"
                  // color="primary"
                  style={{ float: "right", marginTop: "2rem" }}
                >
                  Add Founders Team
                </Button>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

export default CompanyBasicInfo;
