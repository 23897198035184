import * as React from "react";

import Dialog from "@material-ui/core/Dialog";
import SendApplicantForm from "./SendApplicantForm";
import Typography from "@material-ui/core/Typography";
export default function SendAssessment({
  open,
  onClose,
  SelectedID,
  bulkSelect,
  job_id,
  jobTitle,
  data,
  compName,
  currStatusId,
  getFilterString,
  compId,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "20px", width: "600px", height: "550px" }}>
          <Typography
            variant="h4"
            color="initial"
            style={{ marginBottom: "16px" }}
          >
            Send Assessment to Applicants
          </Typography>
          <SendApplicantForm
            open={open}
            SelectedID={SelectedID}
            bulkSelect={bulkSelect}
            job_id={job_id}
            data={data}
            compId={compId}
            jobTitle={jobTitle}
            compName={compName}
            currStatusId={currStatusId}
            getFilterString={getFilterString}
            onClose={onClose}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
