import React, { useState } from "react";
import {
  Grid,
  Button,
  Chip,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fade } from "@material-ui/core/styles";
import {
  CustomActionChipTextField,
  CustomTextField,
} from "../common/InputText/InputField";
import Editor from "../common/InputText/Editor/editor";
import { GETWORK_ORG_DOMAIN } from "../../constants";
import { SuccessAlert, FailureAlert } from "../../util/Notifications";
import { niyuktiAxiosPostReq } from "../../util/apiCall";

function SendApplicantForm({
  open,
  onClose,
  bulkSelect,
  job_id,
  getFilterString,
  SelectedID,
  data,
  compName,
  jobTitle,
  compId,
  currStatusId,
}) {
  const theme = useTheme();

  const accessDate = new Date();
  accessDate.setDate(accessDate.getDate() + 3);

  const day = String(accessDate.getDate()).padStart(2, "0");
  const month = String(accessDate.getMonth() + 1).padStart(2, "0");
  const year = String(accessDate.getFullYear()).slice(-2);
  let hours = accessDate.getHours();
  const minutes = String(accessDate.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;
  const formattedTime = `${hours}:${minutes}${ampm}`;

  const [subject, setSubject] = useState(
    `${compName} | Next Steps for ${jobTitle} Position`
  );

  let userData = JSON.parse(localStorage.getItem("user-data"));

  const [email, setEmail] = useState(`<p>Hello Candidate,</p></br>
  <p>Thank you for applying for the ${jobTitle} position at ${compName}. To better understand your skills and experience, we'd like to invite you to complete a brief assessment. You can access the assessment by clicking on the below button and we'd appreciate it if you could complete it by ${day}/${month}/${year} ${formattedTime}.</p>
  </br>    
  <p>Assessment link: <a href="${GETWORK_ORG_DOMAIN}ai-assessment/${jobTitle
    .toLowerCase()
    .replace(/\s+/g, "-")}-${job_id}">Start Assessment</a></p>
  </br> 
  <p>If you have any questions or need assistance, feel free to reach out us at ${
    userData.email
  } or ${userData.phone}.
  Good luck, and thank you for your interest in joining our team!</p>
  </br>
  <p>Best Regards,</p>
  <p>Team GetWork</p>`);

  const [loading, setLoading] = useState(false);

  const sendAssessmentCall = () => {
    const payload = bulkSelect
      ? {
          subject: subject,
          email_content: email,
          job_id: job_id,
          status_id: currStatusId,
        }
      : {
          subject: subject,
          email_content: email,
          applicant_emails: [SelectedID[0]?.applicant_email],
          student_ids: [SelectedID[0]?.user_id],
          job_id: job_id,
        };

    const url = `/api/ai_job/company/send_ai_assessment_link/?is_bulk=${
      bulkSelect ? 1 : 0
    }&company_id=${compId}&job_id=${job_id}&status_id=${currStatusId}${getFilterString()}`;

    setLoading(true);
    niyuktiAxiosPostReq(url, payload)
      .then((response) => {
        if (response.data.success) {
          SuccessAlert(response.data?.data?.message);
          onClose();
        } else {
          FailureAlert(response.data?.error);
        }
      })
      .catch((error) => {
        FailureAlert("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
        <Grid item md={12}>
          <Autocomplete
            id="tags-readOnly"
            options={data}
            disabled
            value={
              bulkSelect ? { first_name: "All Students" } : SelectedID?.[0]
            }
            getOptionLabel={(option) => option.first_name}
            renderTags={(option) => (
              <Chip
                variant="outlined"
                color="primary"
                style={{
                  backgroundColor: fade(theme.palette.primary.main, 0.1),
                }}
                label={option.first_name}
                size="small"
              />
            )}
            renderInput={(params) => (
              <CustomActionChipTextField
                {...params}
                size="small"
                label="To Applicant"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={12} style={{ marginTop: "12px" }}>
          <CustomTextField
            label="Email Subject"
            variant="outlined"
            size="small"
            fullWidth
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </Grid>
        <Grid style={{ marginTop: "12px" }}>
          <Editor
            className="text"
            state={email}
            setState={setEmail}
            from="desc"
          />
        </Grid>
        <Grid
          item
          md={6}
          style={{
            marginTop: "12px",
            paddingRight: "8px",
            marginBottom: "16px",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid
          item
          md={6}
          style={{
            marginTop: "12px",
            paddingLeft: "8px",
            marginBottom: "16px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={(e) => {
              sendAssessmentCall();
            }}
            style={{ height: "40px" }}
          >
            {!loading ? (
              "Send Assessment"
            ) : (
              <CircularProgress size={18} style={{ color: "white" }} />
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SendApplicantForm;
